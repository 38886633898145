import { Api } from '@lcms/api-definition';
import { loginEndpoint } from './login-endpoint';
import { logoutEndpoint } from './logout-endpoint';
import { meEndpoint } from './me-endpoint';
import { stayAliveEndpoint } from './stay-alive-endpoint';

export const authenticationRouter = Api.getRouter()
	.use(loginEndpoint.inNamespace('auth').toDefinition())
	.use(logoutEndpoint.inNamespace('auth').toDefinition())
	.use(meEndpoint.inNamespace('auth').toDefinition())
	.use(stayAliveEndpoint.inNamespace('session').toDefinition());
