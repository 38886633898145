import { useMount } from '@lcms/react-helpers';
import { useAuthentication, useLogout } from '@lcms/react-identity';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@lcms/react-flex';
import { routes } from '../../routing/routes';
import { AzureUser as User } from '@lcms/identity-microsoft';
import { LoadingPage } from 'src/components/loading';

export default function Page() {
	const authentication = useAuthentication<User>();

	const logout = useLogout();
	const [showLogout, setShowLogout] = useState(false);

	useMount(() => {
		if (logout) {
			logout();
		}
	});

	useEffect(() => {
		if (!authentication) {
			setShowLogout(true);
		}
	}, [authentication]);

	if (showLogout) {
		return (
			<div style={{ position: 'absolute', width: '100%', top: 0, bottom: 0 }}>
				<Flex justification='center' className='h-100'>
					<div className='align-self-center text-center'>
						<span className='display-5'>You have been logged out</span>
						<br />
						<Link to={routes.index}>Return to Home</Link>
					</div>
				</Flex>
			</div>
		);
	}

	return <LoadingPage />;
}
