import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@lcms/react-flex';

export function Loading() {
	return (
		<div className='text-info opacity-50' style={{ fontSize: '22pt' }}>
			<FontAwesomeIcon className='mx-1' style={{ animationDelay: '-0.6s' }} bounce icon={faCircle} />
			<FontAwesomeIcon className='mx-1' style={{ animationDelay: '-0.3s' }} bounce icon={faCircle} />
			<FontAwesomeIcon className='mx-1' bounce icon={faCircle} />
		</div>
	);
}

export function LoadingPage() {
	return (
		<Flex alignment='center' justification='center' className='h-100' column>
			<Loading />
			<div className='display-5'>Logging In</div>
		</Flex>
	);
}
