import { routes } from '../routes';
import { AuthenticatedUser } from '@lcms/identity';
import { AzureUser as User } from '@lcms/identity-microsoft';

export function authorizedRoute() {
	return (user: AuthenticatedUser<User> | null) => {
		if (!user) {
			return {
				redirect: routes.login,
				saveLocation: true,
			};
		}
		return null;
	};
}
