import { api } from '../../api';
import { ImplicitContextConfiguration } from '@lcms/react-identity';
import { AzureUser } from '@lcms/identity-microsoft';

export const authenticationConfig: ImplicitContextConfiguration<AzureUser, AzureUser> = {
	type: 'implicit',
	loginFunction: () => api.auth.login(),
	logoutFunction: () => api.auth.logout(),
	meFunction: () => api.auth.me(),
};
