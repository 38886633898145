import { CompatibilityCheck } from '@lcms/react-compatibility-check';
import { Plugins } from './features/plugins/plugins';
import { Authentication } from './features/authentication';
import { LoadingOverlay } from './features/loading-overlay';
import { Routing } from './routing/routing';

export function App() {
	return (
		<CompatibilityCheck>
			<Authentication>
				<LoadingOverlay>
					<Routing />
				</LoadingOverlay>
			</Authentication>
			<Plugins />
		</CompatibilityCheck>
	);
}
