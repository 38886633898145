import { ApiCall } from '@lcms/api-definition';
import { Authenticated, Unauthenticated } from '@lcms/identity-client';
import { AzureUser } from '@lcms/identity-microsoft';
import { endpoints } from '../../endpoints';

export type EndpointResponse = Authenticated<AzureUser, AzureUser> | Unauthenticated;

export const meEndpoint = new ApiCall<EndpointResponse>().get('me', {
	route: endpoints.me,
});
