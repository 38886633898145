import {
	faArrowProgress,
	faCalendarClock,
	faChurch,
	faExclamationTriangle,
	faFileInvoiceDollar,
	faFilePlus,
	faFileSignature,
	faMoneyCheckDollarPen,
	faSignOut,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@lcms/react-flex';
import { PropsWithChildren, useCallback, useState } from 'react';
import { ReactComponent as Logo } from './logo.svg';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Callback } from '@lcms/helpers';
import { Loading } from 'src/components/loading';
import { useAsyncState } from '@lcms/react-helpers';
import { api } from 'src/api';
import { Link } from 'react-router-dom';
import { routes } from 'src/routing/routes';
import { useLoadingOverlay } from '@lcms/react-loading-overlay';
import { InlineModal, InlineModalTitle } from '@lcms/react-modal';
import { ValidFiles } from 'src/api/endpoints/files/process-endpoint';

export default function Page() {
	const { load } = useLoadingOverlay();

	const [log, setLog] = useState<{ log: string; error: boolean }>();
	const onProcessClick = useCallback(
		(file: ValidFiles) => {
			return load(async () => {
				return api.files.process(file).then((result) => {
					setLog({
						error: !result?.success,
						log: result?.log ?? '',
					});
				});
			});
		},
		[load]
	);

	const onCloseLogClick = useCallback(() => {
		setLog(undefined);
	}, []);

	return (
		<div className='pt-4'>
			<Link className='text-decoration-none float-end me-3' to={routes.logout}>
				<Flex alignment='center'>
					<div className='me-2'>Sign out</div>
					<div>
						<FontAwesomeIcon style={{ fontSize: '16pt' }} icon={faSignOut} />
					</div>
				</Flex>
			</Link>
			<div className='text-center mb-3 p-2 display-5'>
				<Logo height={'1.5em'} className='me-3' />
				<span>Blackbaud Grantmaking Processing</span>
			</div>

			<Section icon={faFilePlus} title='Generate Files'>
				<ButtonGrid>
					<Button onClick={() => onProcessClick('paymentsFile')} icon={faMoneyCheckDollarPen}>
						Generate Payments File
					</Button>
					<Button onClick={() => onProcessClick('requestsFile')} icon={faFileSignature}>
						Generate Requests File
					</Button>
				</ButtonGrid>
			</Section>
			<Section icon={faArrowProgress} title='Process Files'>
				<ButtonGrid>
					<Button onClick={() => onProcessClick('paidPaymentsFile')} icon={faFileInvoiceDollar}>
						Process Paid Payments File
					</Button>
					<Button onClick={() => onProcessClick('organizationFile')} icon={faChurch}>
						Process Vendor Number File
					</Button>
				</ButtonGrid>
			</Section>

			<Section icon={faCalendarClock} title='Pending Files'>
				<PendingFiles />
			</Section>

			<InlineModal
				visible={!!log}
				CloseButton={<button className='btn btn-close' onClick={onCloseLogClick} />}
				dark
				Header={
					<InlineModalTitle>
						<span className={log?.error ? 'text-danger' : undefined}>Process Log {log?.error && '- Error'}</span>
					</InlineModalTitle>
				}
			>
				<pre>{log?.log}</pre>
			</InlineModal>
		</div>
	);
}

function ButtonGrid({ children }: PropsWithChildren<{}>) {
	return (
		<Flex justification='around' wrap>
			{children}
		</Flex>
	);
}

function Button({ icon, onClick, children }: PropsWithChildren<{ onClick: Callback; icon: IconProp }>) {
	return (
		<button type='button' className='btn btn-sm btn-primary m-2' onClick={onClick} style={{ minWidth: '15rem' }}>
			<Flex alignment='center'>
				<div style={{ fontSize: '20pt' }}>
					<FontAwesomeIcon icon={icon} className='me-2' />
				</div>
				<div>{children}</div>
			</Flex>
		</button>
	);
}

function Section({ icon, title, children }: PropsWithChildren<{ title: string; icon: IconProp }>) {
	return (
		<div className='container mb-3'>
			<div>
				<FontAwesomeIcon className='text-muted' style={{ fontSize: '20pt' }} icon={icon} />
				<span className='ms-2' style={{ fontSize: '18pt' }}>
					{title}
				</span>
			</div>
			<div className='py-2 bg-light rounded'>{children}</div>
		</div>
	);
}

function PendingFiles() {
	const [files] = useAsyncState(api.files.pending);

	if (files === null) {
		return (
			<div className='bg-danger p-2 rounded text-white'>
				<FontAwesomeIcon style={{ fontSize: '18pt' }} className='mx-2' icon={faExclamationTriangle} beat />
				Error Checking Pending Files
			</div>
		);
	} else if (!files) {
		return (
			<div className='text-center'>
				<Loading />
			</div>
		);
	}

	return (
		<div className='row'>
			<Flex column className='col-12 col-md-6 border-end border-3'>
				<div className='text-center'>
					<h2>Banner Server</h2>
				</div>
				<Flex grow className='row'>
					<Flex className='col-12 col-lg-6'>
						<FileListing title='Waiting for Banner Job' files={files.onServer.pushed} />
					</Flex>
					<Flex className='col-12 col-lg-6'>
						<FileListing title='Pending Output from Banner' files={files.onServer.awaitingPickup} />
					</Flex>
				</Flex>
			</Flex>
			<Flex column className='col-12 col-md-6'>
				<div className='text-center'>
					<h2>BBGM Server</h2>
				</div>
				<Flex grow className='row'>
					<Flex className='col-12 col-lg-6 '>
						<FileListing title='Waiting for BBGM Job' files={files.local.readyToProcess} />
					</Flex>
					<Flex className='col-12 col-lg-6'>
						<FileListing title='Pending Output from BBGM' files={files.local.awaitingPush} />
					</Flex>
				</Flex>
			</Flex>
		</div>
	);
}

function FileListing({ files, title }: { title: string; files: string[] }) {
	return (
		<Flex column grow>
			<div className='text-center'>
				<h5>{title}</h5>
			</div>

			{files.length ? (
				<ul className='list-group'>
					{files.map((file) => (
						<li key={file} className='list-group-item list-group-item-success'>
							{file}
						</li>
					))}
				</ul>
			) : (
				<Flex grow alignment='center' justification='center' className='bg-danger-subtle rounded border border-danger-subtle'>
					None
				</Flex>
			)}
		</Flex>
	);
}
