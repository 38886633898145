import { AuthenticationProvider, AuthenticationPolling } from '@lcms/react-identity';
import { PropsWithChildren } from 'react';
import { authenticationConfig } from './authentication-config';
import { LoadingPage } from 'src/components/loading';

export function Authentication({ children }: PropsWithChildren<{}>) {
	return (
		<AuthenticationProvider {...authenticationConfig} LoadingPage={<LoadingPage />}>
			<AuthenticationPolling intervalSeconds={15} meFunction={authenticationConfig.meFunction} />
			{children}
		</AuthenticationProvider>
	);
}
