import { Flex } from '@lcms/react-flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGearComplex } from '@fortawesome/pro-solid-svg-icons';

export function Overlay() {
	return (
		<div
			style={{
				position: 'fixed',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				background: '#000000d1',
				backdropFilter: 'blur(2px)',
			}}
		>
			<Flex className='h-100 text-white' justification='center' alignment='center'>
				<div className='text-center'>
					<FontAwesomeIcon icon={faGearComplex} spin style={{ fontSize: '70pt' }} />
					<div className="display-2">
						Working
					</div>
				</div>
			</Flex>
		</div>
	);
}
