import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { RouteAsClass } from '@lcms/react-router-helpers';
import { AuthorizedRoute } from '@lcms/react-identity-routes';
import { routes } from './routes';

import HomePage from '../pages/home/page';
import LoginPage from '../pages/login/page';
import LogoutPage from '../pages/logout/page';
import { guards } from './guards';

export function Routing() {
	return (
		<BrowserRouter>
			<RouteAsClass />
			<Switch>
				<Route render={() => <LogoutPage/>} exact path={routes.logout} />
				<Route render={() => <LoginPage/>} exact path={routes.login} />
				<AuthorizedRoute render={() => <HomePage/>} exact path={routes.index} redirect={null} isAuthorized={guards[routes.index]}/>
				<Route render={() => <div>404</div>} />
			</Switch>
		</BrowserRouter>
	);
}
