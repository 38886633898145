import { ImplicitLoginPage } from '@lcms/react-identity-routes';
import { routes } from '../../routing/routes';
import { LoadingPage } from 'src/components/loading';

export default function Page() {
	return (
		<ImplicitLoginPage defaultRoute={routes.index}>
			<LoadingPage />
		</ImplicitLoginPage>
	);
}
