import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = {
	success: boolean;
	log: string;
};

export type ValidFiles = 'paymentsFile' | 'requestsFile' | 'paidPaymentsFile' | 'organizationFile';

export const processEndpoint = new ApiCall<EndpointResponse>().get('process', {
	route: endpoints.processFiles,
});

export default processEndpoint;
