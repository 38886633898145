import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = {
    onServer: {
        pushed: string[];
        awaitingPickup: string[];
    };
    local: {
        readyToProcess: string[];
        awaitingPush: string[];
    }
};

export const pendingEndpoint = new ApiCall<EndpointResponse>().get('pending', {
	route: endpoints.pendingFiles,
});

export default pendingEndpoint;